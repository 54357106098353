<template>
  <div class="healthInno">
    <div class="hero">
      <img
        src="@/assets/img/home/output.gif"
        alt="hero-img"
        class="img-fluid"
        v-if="type == 'desktop'"
      />
      <img
        src="@/assets/img/home/mobile-output.gif"
        alt="hero-img"
        class="img-fluid"
        v-else
      />
    </div>
    <div class="health-care-inno text-center py-5">
      <h2 class="font-spaced font-weight-bold">HEALTHCARE INNOMATCH</h2>
    </div>
    <div class="container-xl">
      <div class="healthcare-detail py-5">
        <p>
          The Healthcare InnoMatch 2022 by Centre for Healthcare Innovation
          (CHI), supported by Temasek Foundation and Ministry of Health is
          seeking for innovative, market-ready or near market ready solutions to
          address critical and emerging healthcare needs!
        </p>
        <p>
          Healthcare InnoMatch 2022 is an initiative of the CHI Start-up
          Enterprise Link (CHISEL). CHISEL is a platform that will accelerate
          the adoption of near-market or market ready healthcare innovations
          that are fit for mainstream use, to bridge the last mile from
          innovation to adoption at speed, at scale.
        </p>
        <a
          href="https://chisel.agorize.com/en/challenges/healthcare-innomatch-2022"
          target="_blank"
        >
          <div class="btn btn-fidn-more">FIND OUT MORE</div></a
        >
      </div>
    </div>
    <div class="poster">
      <img
        src="@/assets/img/healthcareInno/inno match poster.png"
        alt="poster"
        class="img-fluid"
        v-if="type == 'desktop'"
      />
      <img
        src="@/assets/img/healthcareInno/mobile/inno match posters.png"
        alt="poster"
        v-else
        class="img-fluid"
      />
    </div>

    <div class="container-xl">
      <div class="videos py-5">
        <p class="text-center font-avenir-bold">
          Winning innovators share their experience!
        </p>
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-4 col-sm-10 col-10">
            <a
              href="javascript:void(0)"
              @click="openModal('https://www.youtube.com/embed/HYaIC2roeGo')"
            >
              <img
                src="@/assets/img/healthcareInno/Video thumbnail/Winning innovator/v1.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-10 col-10">
            <a
              href="javascript:void(0)"
              @click="openModal('https://www.youtube.com/embed/04p3Ks-COSw')"
            >
              <img
                src="@/assets/img/healthcareInno/Video thumbnail/Winning innovator/2.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-10 col-10">
            <a
              href="javascript:void(0)"
              @click="openModal('https://www.youtube.com/embed/9p-IC0pPPbo')"
            >
              <img
                src="@/assets/img/healthcareInno/Video thumbnail/Winning innovator/3.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
        </div>
        <p class="text-center font-avenir-bold mt-4">
          Top hospital executives give their view of the innovation pitches and
          start-ups!
        </p>
        <div class="row">
          <div class="col-12">
            <a
              href="javascript:void(0)"
              @click="openModal('https://www.youtube.com/embed/hclc-36ackA')"
            >
              <img
                src="@/assets/img/healthcareInno/Video thumbnail/Top hospital.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="sponsors py-5">
        <img
          src="@/assets/img/healthcareInno/sponsors desktop.png"
          alt="Sponsors"
          class="img-fluid"
          v-if="type == 'desktop'"
        />
        <img
          src="@/assets/img/healthcareInno/mobile/sponsors mobile.png"
          alt="Sponsors"
          class="img-fluid"
          v-else
        />
      </div>
    </div>
    <div class="footer">
      <div v-if="type == 'desktop'">
        <div class="container-xl">
          <div class="row py-3">
            <div class="col-3">
              <p class="font-weight-bold">Organised by</p>
              <img
                src="@/assets/img/footer/CHI-Logo®-Colour.png"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="col-3 text-center">
              <p class="font-weight-bold">Supported by</p>
              <img
                src="@/assets/img/footer/logo-moh.png"
                alt=""
                class="img-fluid"
              />
            </div>
            <div
              class="col-4 ng-teng d-flex flex-column justify-content-center"
            >
              <img
                src="@/assets/img/footer/Ng teng.png"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="col-2">
              <p class="mb-0 font-weight-bold">Download the</p>
              <p class="font-weight-bold">CHI Connect App</p>
              <a
                href="https://apps.apple.com/au/app/chi-connect/id1525160790"
                target="_blank"
              >
                <img
                  src="@/assets/img/footer/apple ios.png"
                  alt=""
                  class="img-fluid mb-2"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=sg.chi.app&hl=en_SG&gl=US"
                target="_blank"
              >
                <img
                  src="@/assets/img/footer/android.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="container-xl">
          <div class="row py-3">
            <div class="col-5 py-1">
              <p class="font-weight-bold">Organised by</p>
              <img
                src="@/assets/img/footer/CHI-Logo®-Colour.png"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="col-7 py-1">
              <p class="mb-0 font-weight-bold">Download the</p>
              <p class="font-weight-bold">CHI Connect App</p>
              <div class="row">
                <div class="col-8">
                  <a
                    href="https://apps.apple.com/au/app/chi-connect/id1525160790"
                    target="_blank"
                  >
                    <img
                      src="@/assets/img/footer/apple ios.png"
                      alt=""
                      class="img-fluid mb-2"
                    />
                  </a>
                </div>
                <div class="col-8">
                  <a
                    href="https://play.google.com/store/apps/details?id=sg.chi.app&hl=en_SG&gl=US"
                    target="_blank"
                  >
                    <img
                      src="@/assets/img/footer/android.png"
                      alt=""
                      class="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-5 py-1">
              <p class="font-weight-bold">Supported by</p>
              <img
                src="@/assets/img/footer/logo-moh.png"
                alt=""
                class="img-fluid"
              />
            </div>
            <div
              class="col-7 py-1 ng-teng-mobile d-flex flex-column justify-content-center"
            >
              <img
                src="@/assets/img/footer/Ng teng.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright py-2">
      <div class="container-xl">
        <div class="d-flex justify-content-between">
          <p>Copyright © to Klobbi.sg</p>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="videoModal"
      tabindex="-1"
      aria-labelledby="videoModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>
            <div
              class="embed-responsive embed-responsive-16by9"
              v-if="!hide_video"
            >
              <iframe
                class="embed-responsive-item"
                src=""
                id="video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "healthInno",
  data() {
    return {
      type: "desktop",
      videoSrc: "",
      hide_video: true,
    };
  },
  mounted() {
    let self = this;

    $("#videoModal").on("shown.bs.modal", function (e) {
      self.hide_video = false;
      setTimeout(() => {
        $("#video").attr("src", self.videoSrc);
      }, 500);
    });
    $("#videoModal").on("hide.bs.modal", function (e) {
      self.hide_video = true;
      $("#video").attr("src", self.videoSrc);
    });
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onResize() {
      let mq = window.matchMedia("(max-width: 570px)");
      if (mq.matches) {
        this.type = "mobile";
      } else {
        this.type = "desktop";
      }
    },
    openModal(link) {
      this.videoSrc = link;
      $("#videoModal").modal("show");
    },
  },
};
</script>

<style lang="scss" scoped>
.healthInno {
  overflow: hidden;

  .und-red {
    border-bottom: 2px solid #bd1e2d;
  }
  .white-line {
    border-bottom: 2px solid #fff;
  }

  .health-care-inno {
    background-image: linear-gradient(to right, #8d000d, #cf1c2d);
    color: #fff;

    h2 {
      margin-bottom: 0px !important;
    }
  }
  .healthcare-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-fidn-more {
      background-image: linear-gradient(to right, #8d000d, #cf1c2d) !important;
      color: #fff;
      font-family: "Spaced";
    }
  }
  .footer {
    border-bottom: 1px solid #eaebee;
  }
  .copyright {
    p {
      color: #8a91a1;
    }
  }
  .connect {
    background-image: linear-gradient(to right, #ffa872, #ff8484);
  }
  .mobile-connect {
    // background-image: url("../assets/img/ticket/mobile/download the chi app.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .footer {
    border-bottom: 1px solid #eaebee;
    background: #f8f8f8;
    .ng-teng {
      p {
        font-size: 0.7rem;
      }
    }
    .ng-teng-mobile {
      font-size: 0.4rem;
    }
  }
  .close {
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 999;
    font-size: 2rem;
    font-weight: normal;
    color: #fff;
    opacity: 1;
  }
  @media only screen and (max-width: 600px) {
    .close {
      position: absolute;
      right: 20px;
      top: 0px;
      z-index: 999;
      font-size: 2rem;
      font-weight: normal;
      color: #000;
      -webkit-text-stroke: 1px black;
      -webkit-text-fill-color: white;
    }
  }
}
</style>
